export enum Path {
  Home = "/",
  About = "/about",
  SpellingLetters = "/games/spellingLetters",
  SpellingWords = "/games/spellingWords",
  HangMan = "/games/hangMan",
  Wordle = "/games/wordle",
  Games = "/games",
  //Leaderboard = "/leaderboard",
  // Profile = "/profile",
  // SignIn = "/signin",
  // SignUp = "/signup",
  HandSigns = "/handSigns",
  // ForgotPasswordID = "/forgot-password/:id",
  // ForgotPassword = "/forgot-password",
}
